import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import DonationModule from './DonationModule';

const DonationModuleQuery = props => {
  const donationModuleEyebrow = idx(props, _ => _.donationModuleEyebrow);
  const donationModuleTitle = idx(props, _ => _.donationModuleTitle);
  const donationModuleSubtitle = idx(props, _ => _.donationModuleSubtitle);

  return (
    <DonationModule
      eyebrow={donationModuleEyebrow}
      title={donationModuleTitle}
      subtitle={donationModuleSubtitle}
    />
  );
};

export const query = graphql`
  fragment DonationModule on ContentfulGlobalSiteData {
    donationModuleEyebrow
    donationModuleTitle
    donationModuleSubtitle
  }
`;

export default DonationModuleQuery;
