/* eslint-disable */

import React from 'react';
import { Link, navigate } from 'gatsby';
import Styles from './GrantsOverlay.module.scss';
import { TweenMax } from 'gsap';
import Img from 'gatsby-image/withIEPolyfill';
import { css, trackEvent } from '@utils';

const MOBILE_WIDTH = 768;

export default class GrantsOverlay extends React.Component {
  constructor() {
    super();
    this.translateY = 0;
    this.percent = 0;
    this.enabled = false;
    this.mobile = false;
    this.scrollPosition = 0;
    this.prevYear = -1;
    this.param = { scroll: 0, scroll2: 0 };
    this.getDefaultEditorialData = this.getDefaultEditorialData.bind(this);
    this.onNewYearClick = this.onNewYearClick.bind(this);
    this.onDonationClick = this.onDonationClick.bind(this);
  }

  componentDidMount() {
    this.scrollDesktop = document.querySelector(`.scrollElement`);
    this.scrollMobile = document.querySelector(
      `.${Styles.scrollMobileWrapper}`
    );
    this.desktopColumn = document.querySelector(`.desktopColumn`);
  }

  setScroll(status) {
    const body = document.body;
    if (status) {
      this.scrollPosition = window.pageYOffset;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${this.scrollPosition}px`;
      body.style.width = '100%';
      document.documentElement.style.overflow = 'hidden';
    } else {
      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      document.documentElement.style.removeProperty('overflow');
      window.scrollTo(0, this.scrollPosition);
    }
  }

  componentDidUpdate() {
    if (this.props.active || this.prevYear !== this.props.year) {
      this.prevYear = this.props.year;

      this.mobile = window.innerWidth <= MOBILE_WIDTH;

      if (!this.enabled) {
        this.setScroll(true);
        this.enabled = true;
      }

      this.translateY = 0;

      this.param.scroll = this.scrollMobile.scrollTop;

      TweenMax.killTweensOf(this.param);

      if (this.param.scroll !== 0)
        TweenMax.to(this.param, 0.5, {
          scroll: 0,
          onUpdate: () => {
            this.scrollMobile.scrollTo(0, this.param.scroll);
          },
        });

      this.param.scroll2 = this.scrollDesktop.scrollTop;

      TweenMax.to(this.param, 0.5, {
        scroll2: this.translateY,
        onUpdate: () => {
          this.scrollDesktop.scrollTo(0, this.param.scroll2);
        },
      });
    } else {
      if (this.enabled) {
        this.setScroll(false);
        this.enabled = false;
      }
    }
  }

  componentWillUnmount() {
    this.setScroll(false);
    this.enabled = false;
  }

  getDefaultEditorialData(year) {
    return {
      eyebrow: 'Overview',
      title: `${year} Grants`,
      subtitle:
        'Life-saving equipment. Live fire training buildings. Complete technology upgrades. In 2019 we provided grants to fire departments in twenty one states across the country, including four new states.',
      image: OVERLAY_IMAGE_DEMO,
    };
  }

  onDonationClick(e) {
    e.preventDefault();

    trackEvent({
      category: 'Impact',
      action: `${window.location.origin}/donate`,
      label: 'Make A Donation',
    });
    navigate('/donate')
  }

  onNewYearClick(newYear) {
    const { data } = this.props;
    this.props.updateYear(newYear);

    trackEvent({
      category: 'Impact',
      action: null,
      label: data[newYear].year,
    });
  }

  render() {
    const year = this.props.year;
    const data = this.props.data[year];
    const states = data.states.length;
    const years = this.props.data.map(el => el.year);
    const editorialData =
      this.props.editorialData && this.props.editorialData[years[year]]
        ? this.props.editorialData[years[year]]
        : this.getDefaultEditorialData(years[year]);
    const { eyebrow, title, subtitle, image } = editorialData;

    const statesData = Array.from(data.states).sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();

      let AA = nameA === String(this.props.stateSelected).toUpperCase();
      let BB = nameB === String(this.props.stateSelected).toUpperCase();

      if (nameA < nameB) {
        return BB ? 1 : -1;
      }
      if (nameA > nameB) {
        return AA ? -1 : 1;
      }
      return 0;
    });

    let grants = [];
    let keyValue = 0;

    statesData.map(state => {
      state.grants.map(grant => {
        keyValue += 1;

        const stateSelected = state.name === this.props.stateSelected;

        function createMarkup() {
          return { __html: `${grant.grant}` };
        }

        grants.push(
          <div key={`grant${keyValue}`} className={Styles.grant}>
            <div
              className={css(
                Styles.location,
                stateSelected && Styles.locationSelected
              )}
            >{`${grant.location}, ${state.name}`}</div>
            <div
              className={css(
                Styles.location,
                stateSelected && Styles.locationSelected
              )}
            >{`${grant.population} POPULATION SERVED`}</div>
            <div className={Styles.beneficiary}>{grant.beneficiary}</div>
            <div
              className={Styles.donation}
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
        );
      });
    });

    return (
      <div
        className={`${Styles.wrapper} ${
          this.props.active ? Styles.wrapperActive : ''
        }`}
      >
        <div className={Styles.scrollMobileWrapper}>
          <div className={Styles.column}>
            <div className={Styles.overview}>{eyebrow}</div>
            <div className={Styles.year}>{title}</div>
            <div className={Styles.copy}>{subtitle}</div>
            <div className={Styles.grantsImageDesktop}>
              {image && image.fluid && <Img fluid={image.fluid} />}
            </div>
            <Link
              to="/donate"
              onClick={this.onDonationClick}
              className={`${Styles.grantsButtonDesktop} ${
                image ? Styles.grantsButtonDesktop_image : ''
              }`}
            >
              <div className={Styles.grantsButtonDesktop_copy}>
                MAKE A DONATION
              </div>
              <svg
                className={Styles.grantsButtonDesktop_arrow}
                width="29.96"
                height="21.178"
                viewBox="0 0 29.96 21.178"
              >
                <g
                  id="Group_415"
                  data-name="Group 415"
                  transform="translate(-902.145 -3095.444)"
                >
                  <g
                    id="Group_320"
                    data-name="Group 320"
                    transform="translate(-59 -2463)"
                  >
                    <g
                      id="Group_317"
                      data-name="Group 317"
                      transform="translate(988.983 5560.565) rotate(90)"
                    >
                      <path
                        id="Path_77"
                        data-name="Path 77"
                        d="M0,0,8.468,8.468,16.935,0"
                        transform="translate(16.935 8.468) rotate(180)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="3"
                      />
                      <line
                        id="Line_32"
                        data-name="Line 32"
                        y2="26"
                        transform="translate(8.468 0.339)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="3"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
          </div>

          <div className={`${Styles.column} scrollElement`}>
            <div className="desktopColumn">
              <div className={Styles.grantsWrapper}>{grants}</div>

              <Link to="/donate" className={Styles.grantButtonMobile}>
                APPLY FOR A GRANT
              </Link>

              <div className={Styles.menu}>
                {years[year - 1] && (
                  <div
                    onClick={() => {
                      this.onNewYearClick(year - 1);
                    }}
                    className={`${Styles.menuOption}`}
                  >
                    <div className={Styles.arrowContainer}>
                      <svg
                        style={{ transform: 'rotate(180deg)' }}
                        className={Styles.menuArrow}
                        width="7.621"
                        height="12.243"
                        viewBox="0 0 7.621 12.243"
                      >
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M0,0,2.25,2.25,4,4,5.667,2.333,8,0"
                          transform="translate(2.121 10.121) rotate(-90)"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                      </svg>
                    </div>
                    <div
                      className={Styles.menuYear}
                      style={{ textAlign: 'right' }}
                    >
                      {' '}
                      {years[year - 1]}
                    </div>
                  </div>
                )}

                {years[year + 1] && (
                  <div
                    onClick={() => {
                      this.onNewYearClick(year + 1);
                    }}
                    className={`${Styles.menuOption}`}
                    style={{ float: 'right' }}
                  >
                    <div className={Styles.menuYear}> {years[year + 1]}</div>
                    <div className={Styles.arrowContainer}>
                      <svg
                        className={Styles.menuArrow}
                        width="7.621"
                        height="12.243"
                        viewBox="0 0 7.621 12.243"
                      >
                        <path
                          id="Path_90"
                          data-name="Path 90"
                          d="M0,0,2.25,2.25,4,4,5.667,2.333,8,0"
                          transform="translate(2.121 10.121) rotate(-90)"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            this.props.overlayState(false);
          }}
          className={Styles.closeButton}
        >
          BACK
        </div>
      </div>
    );
  }
}
