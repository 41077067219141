/* eslint-disable */

import React from "react";

import Styles from "./StateSelector.module.scss";

export default class StateSelector extends React.Component {

    constructor() {
        super();
        this.state = {ready: false}
    }

    componentDidMount() {

        this.introAnimation = setTimeout(() => {
            this.setState({ready: true});
        }, this.props.delay);

    }

    componentWillUnmount() {
        clearTimeout(this.introAnimation);
    }

    render() {

        return (
            <div  onClick = {() => {this.props.onClick(this.props.name)}} key = {this.props.index} className = {`${Styles.dotContainer} ${this.state.ready ? Styles.dotContainerActive : ""}`} style = {{top: `${this.props.top}%`, left: `${this.props.left}%`}}>
                <div className = {`${Styles.dot} ${this.state.ready ? Styles.dotActive : ""}`} />
                <div className = {Styles.stateBox}>{this.props.name}
                    <span className = {Styles.symbol}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.308" height="14" viewBox="0 0 14.308 14">
                          <g id="Group_131" data-name="Group 131" transform="translate(-659.5 -7095.808)">
                            <g id="Group_130" data-name="Group 130" transform="translate(661 7097)">
                              <line id="Line_40" data-name="Line 40" y2="11" transform="translate(6 0.308)" fill="none" stroke="#ffa200" strokeLinecap="round" strokeWidth="3"/>
                              <line id="Line_41" data-name="Line 41" y2="11.308" transform="translate(11.308 5.654) rotate(90)" fill="none" stroke="#ffa200" strokeLinecap="round" strokeWidth="3"/>
                            </g>
                          </g>
                        </svg>
                    </span>
                </div>
             </div>
        )

    }

}