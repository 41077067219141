import React from 'react';
import { InView } from 'react-intersection-observer';
import { Link, navigate } from 'gatsby';
import { css, trackEvent } from '@utils';
import Styles from './DonationModule.module.scss';

const DonationModule = ({ eyebrow, title, subtitle }) => {
  const onDonateClick = e => {
    e.preventDefault();
    trackEvent({
      category: 'Donation Module',
      action: `${window.location.origin}/donate`,
      label: 'Donate',
    });

    navigate('/donate');
  };

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          id="DonationModule"
          ref={ref}
          className={css(Styles.donationModule, inView && Styles.inView)}
        >
          <div className={Styles.copyContainer}>
            <div className={Styles.eyebrow}>{eyebrow}</div>
            <h2 className={Styles.title}>{title}</h2>
            <h4 className={Styles.subtitle}>{subtitle}</h4>
            <Link to="/donate" onClick={onDonateClick}>
              <div className={Styles.donateButton}>Donate</div>
            </Link>
          </div>
        </div>
      )}
    </InView>
  );
};

export default DonationModule;
