import React, { useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import Img from 'gatsby-image/withIEPolyfill';
import { ShortArrow } from '@shared/Icons/Icons';
import { css, getSlideIndexFromProgress, isTouchDevice } from '@utils';
import * as gsap from 'gsap';
import Styles from './StoryImageCarousel.module.scss';

const StoryImageCarousel = ({ carouselSlides, windowWidth }) => {
  const carouselRef = useRef(null);
  const carouselProgress = useRef(0);
  const currentProgress = useRef(0);
  const pressPoint = useRef(null);
  const carouselTweenId = useRef(null);
  const positionTweenId = useRef(null);
  const slideIndex = useRef(0);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);
  const pressingDown = useRef(false);

  const slideSize = useRef(getSlideSize(windowWidth)); // % of width
  const guestureDirection = useRef('left');

  const moveCarousel = () => {
    carouselRef.current.style.transform = `translate3d(${currentProgress.current}px, 0, 0)`;
  };

  const tweenPosition = (from, to) => {
    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const carouselPosition = { x: from };
    positionTweenId.current = gsap.TweenLite.to(carouselPosition, 0.2, {
      x: to,
      ease: gsap.Expo.easeOut,
      onUpdate: () => {
        currentProgress.current = carouselPosition.x;
        moveCarousel();
      },
    });
  };

  const tweenToSlide = index => {
    const progressTween = { val: currentProgress.current };
    slideIndex.current = index;
    updateDesktopArrows(index);

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    carouselTweenId.current = gsap.TweenLite.to(progressTween, 0.7, {
      val: -1 * index * (windowWidth * (slideSize.current / 100)),
      onUpdate: () => {
        currentProgress.current = progressTween.val;
        carouselProgress.current = progressTween.val;

        moveCarousel();
      },
    });
  };

  const onArrowClick = direction => {
    let newIndex = 0;
    if (direction === 'left') {
      newIndex = slideIndex.current - 1;
    } else {
      newIndex = slideIndex.current + 1;
    }

    const constrainedSlideIndex = getConstrainedIndex(
      newIndex,
      carouselSlides.length,
      100 / slideSize.current // items per width =>  2.5 etc
    );

    tweenToSlide(constrainedSlideIndex, gsap.Power2.easeInOut, 1.2);
  };

  const updateDesktopArrows = (index = 0) => {
    const itemsPerWidth = 100 / slideSize.current;
    const maxSlideIndex =
      carouselSlides.length > Math.floor(itemsPerWidth)
        ? carouselSlides.length - Math.floor(itemsPerWidth)
        : carouselSlides.length - 1;

    if (index === 0) {
      leftArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowLeft,
        Styles.disabled
      );
    } else {
      leftArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowLeft
      );
    }

    if (index === maxSlideIndex) {
      rightArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowRight,
        Styles.disabled
      );
    } else {
      rightArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowRight
      );
    }
  };

  const onPressStart = e => {
    if (e.touches) {
      pressPoint.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    } else {
      pressPoint.current = { x: e.clientX, y: e.clientY };
    }
    pressingDown.current = true;
  };

  const onPressMove = e => {
    if (!pressPoint.current) {
      return;
    }

    if (!pressingDown.current) {
      return;
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;

    guestureDirection.current =
      clientX < pressPoint.current.x
        ? 'left'
        : clientX > pressPoint.current.x
        ? 'right'
        : null;

    const timelineDelta = clientX - pressPoint.current.x;
    const prevProgress = currentProgress.current;

    tweenPosition(prevProgress, carouselProgress.current + timelineDelta);
  };

  const onPressEnd = () => {
    pressingDown.current = false;
    const progressX =
      (-1 * currentProgress.current) /
      ((slideSize.current / 100) * windowWidth);
    const progressModulus = progressX % 1;

    const progressIndex =
      guestureDirection.current === 'left'
        ? Math.floor(progressX)
        : guestureDirection.current === 'right'
        ? Math.ceil(progressX)
        : Math.round(progressX);

    const newIndex = getSlideIndexFromProgress(
      guestureDirection.current,
      progressModulus,
      progressIndex
    );

    const constrainedSlideIndex = getConstrainedIndex(
      newIndex,
      carouselSlides.length,
      100 / slideSize.current // items per width =>  2.5 etc
    );

    tweenToSlide(constrainedSlideIndex);

    carouselProgress.current = currentProgress.current;
  };

  const onResize = () => {
    tweenToSlide(slideIndex.current);
  };

  const bindListeners = () => {
    if (!isTouchDevice) {
      window.addEventListener('resize', onResize);
    }
  };

  useEffect(() => {
    bindListeners();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.storyImageCarousel, inView && Styles.inView)}
        >
          <div className={Styles.carouselWrapper}>
            <div
              className={css(
                Styles.carouselArrow,
                Styles.carouselArrowLeft,
                Styles.disabled
              )}
              ref={leftArrowRef}
              onClick={() => {
                onArrowClick('left');
              }}
            >
              <ShortArrow iconStyles={Styles.arrowIcon} />
            </div>
            <div
              className={css(Styles.carouselArrow, Styles.carouselArrowRight)}
              ref={rightArrowRef}
              onClick={() => {
                onArrowClick('right');
              }}
            >
              <ShortArrow iconStyles={Styles.arrowIcon} />
            </div>
            <div
              role="presentation"
              className={Styles.carousel}
              ref={carouselRef}
              style={{
                width: `${carouselSlides.length * 100}%`,
              }}
              onTouchStart={onPressStart}
              onTouchMove={onPressMove}
              onTouchEnd={onPressEnd}
              onMouseDown={onPressStart}
              onMouseMove={onPressMove}
              onMouseUp={onPressEnd}
              onMouseLeave={onPressEnd}
            >
              {carouselSlides.map((carouselImage, index) => {
                return (
                  <div
                    key={index}
                    className={css(Styles.cardSlide)}
                    style={{
                      width: `${slideSize.current / carouselSlides.length}%`,
                      transitionDelay: `${index * 100}ms`,
                    }}
                  >
                    {carouselImage && carouselImage.fluid && (
                      <Img
                        className={Styles.cardSlideImage}
                        fluid={carouselImage.fluid}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </InView>
  );
};

const getConstrainedIndex = (newIndex, total, itemsPerWidth) => {
  return Math.min(
    Math.max(0, newIndex),
    total > Math.floor(itemsPerWidth)
      ? total - Math.floor(itemsPerWidth)
      : total - 1
  );
};

const getSlideSize = width => {
  if (width <= 600) {
    return 85;
  }
  if (width <= 900) {
    return 60;
  }

  return 35;
};

export default StoryImageCarousel;
