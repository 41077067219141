import React from 'react';
import { InView } from 'react-intersection-observer';
import { css } from '@utils';
import Styles from './TextHero.module.scss';

const TextHero = ({ eyebrow, title, subtitle }) => {
  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.textHero, inView && Styles.inView)}
        >
          <div className={Styles.copyContainer}>
            <div className={Styles.eyebrow}>{eyebrow}</div>
            <h2 className={Styles.title}>{title}</h2>
            <p className={Styles.subtitle}>{subtitle}</p>
          </div>
        </div>
      )}
    </InView>
  );
};

export default TextHero;
