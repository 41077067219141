import React, { useState } from 'react';
import { PlusIcon } from '@shared/Icons/Icons';
import Img from 'gatsby-image/withIEPolyfill';
import { css, isTouchDevice } from '@utils';
import Styles from './Grantee.module.scss';

const Grantee = ({
  index,
  storyActive,
  onStoryClick,
  granteeItemSize,
  inView,
  thumbnailEyebrow,
  thumbnailTitle,
  thumbnailImage,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={css(
        Styles.grantee,
        imageLoaded && Styles.imageLoaded,
        inView && Styles.inView
      )}
      style={{
        width: `${granteeItemSize}%`,
        transitionDelay: `${index * 100 + 700}ms`,
      }}
      onClick={() => {
        if (!storyActive && isTouchDevice) {
          onStoryClick(index)
        }
      }}
  
    >
      <div className={Styles.granteeImage}>
        {thumbnailImage && thumbnailImage.fluid && (
          <Img
            fluid={thumbnailImage.fluid}
            onLoad={() => {
              setImageLoaded(true);
            }}
            width="1130px"
            height="1400px"
          />
        )}
      </div>
      <div className={Styles.copyTintBackground} />

      <div className={Styles.granteeCopyContainer}>
        <button
          className={Styles.plusIconButton}
          aria-label="Show story"
          type="button"
          onClick={() => {
            onStoryClick(index)
          }}
        >
          <PlusIcon iconStyles={Styles.plusIcon} />
        </button>
        <div className={Styles.granteeTitle}>{thumbnailEyebrow}</div>
        <div className={Styles.granteeName}>{thumbnailTitle}</div>
      </div>
    </div>
  );
};

export default Grantee;
