import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { css } from '@utils';
import { ShortFatArrow } from '@shared/Icons/Icons';
import Styles from './GranteeStorySideNav.module.scss';
import FeaturedStoryContent from '../FeaturedStories/FeaturedStoryContent';

const GranteeStorySideNav = ({
  storiesList,
  open,
  onClose,
  setBodyFixed,
  activeStory,
  onFooterCtaClick,
  bodyEl,
  windowWidth,
}) => {
  const sideNavScrollContainerRef = useRef(null);
  const hasPreviousStory = activeStory > 0;
  const hasNextStory = activeStory < storiesList.length - 1;
  const scrollToTop = () => {
    sideNavScrollContainerRef.current.scrollTop = 0;
  };
  const [sideNavWidth, setSideNavWidth] = useState(windowWidth);
  const sideNavRef = useRef(null);

  const onResize = () => {
    setSideNavWidth(sideNavRef.current.getBoundingClientRect().width);
  };

  useEffect(() => {
    setSideNavWidth(sideNavRef.current.getBoundingClientRect().width);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (!bodyEl) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div className={css(Styles.sideNav, open && Styles.open)}>
          <div
            className={Styles.tintBackground}
            onClick={() => {
              onClose();
              setBodyFixed(false);
            }}
          />

          <div className={Styles.sideNavContentContainer} ref={sideNavRef}>
            <div
              className={Styles.sideNavBackBtn}
              onClick={() => {
                onClose();
                setBodyFixed(false);
              }}
            >
              Back
            </div>
            <div
              className={Styles.sideNavScrollContainer}
              ref={sideNavScrollContainerRef}
            >
              <div className={Styles.sideNavContent}>
                {storiesList.map((story, index) => {
                  return (
                    <div
                      className={css(
                        Styles.sideNavStory,
                        index === activeStory && Styles.active
                      )}
                      key={index}
                    >
                      <FeaturedStoryContent
                        windowWidth={sideNavWidth}
                        {...story}
                      />
                     
                    </div>
                  );
                })}

                <div className={Styles.footerButtons}>
                  <div
                    className={css(
                      Styles.footerButtonCta,
                      Styles.leftCtaButton,
                      !hasPreviousStory && Styles.hidden,
                      Styles.backBtn
                    )}
                    onClick={() => {
                      onFooterCtaClick(activeStory - 1);
                      scrollToTop();
                    }}
                  >
                    <ShortFatArrow iconStyles={Styles.leftFooterCtaArrow} />
                    <div className={Styles.footerButtonText}>Back</div>
                  </div>

                  <div
                    className={css(
                      Styles.footerButtonCta,
                      Styles.rightCtaButton,
                      !hasNextStory && Styles.hidden,
                      Styles.nextFeatureBtn
                    )}
                    onClick={() => {
                      onFooterCtaClick(activeStory + 1);
                      scrollToTop();
                    }}
                  >
                    <div className={Styles.footerButtonText}>Next</div>
                    <ShortFatArrow iconStyles={Styles.rightFooterCtaArrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
        bodyEl
      )}
    </>
  );
};

export default GranteeStorySideNav;
