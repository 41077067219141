import React, { useState, useRef, useEffect } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { ScrollArrow } from '@shared/Icons/Icons';
import useWindowSize from '@hooks/useWindowSize';
import { css, scrollTo } from '@utils';
import Styles from './Hero.module.scss';

const Hero = ({
  eyebrow,
  title,
  subtitle,
  mobileHeroImage,
  desktopHeroImage,
}) => {
  const { windowWidth } = useWindowSize();
  const [windowSize, setWindowSize] = useState(windowWidth);
  const [imageLoaded, setImageLoaded] = useState(false);
  const heroRef = useRef(null);

  const onScrollCtaClick = () => {
    const scrollHeight = heroRef.current.getBoundingClientRect().height;
    scrollTo(scrollHeight, 1200); // offset, duration
  };

  const onResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div
      className={css(Styles.hero, imageLoaded && Styles.loaded)}
      ref={heroRef}
    >
      {windowSize <= 768 ? (
        <div className={Styles.mobileImage}>
          {mobileHeroImage && mobileHeroImage.fluid && (
            <Img
              onLoad={() => {
                setImageLoaded(true);
              }}
              fluid={mobileHeroImage.fluid}
              objectFit="cover"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
      ) : (
        <div className={Styles.desktopImage}>
          {desktopHeroImage && desktopHeroImage.fluid && (
            <Img
              onLoad={() => {
                setImageLoaded(true);
              }}
              fluid={desktopHeroImage.fluid}
              objectFit="cover"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
      )}
      <div className={Styles.backgroundImageMask} />

      <div className={Styles.copyTintBackground} />
      <div className={Styles.copyContainer}>
        <div className={Styles.eyebrow}>{eyebrow}</div>
        <h2 className={Styles.title}>{title}</h2>
        <h4 className={Styles.subtitle}>{subtitle}</h4>
      </div>
      <div className={Styles.scrollCta} onClick={onScrollCtaClick}>
        <ScrollArrow iconStyles={Styles.scrollArrow} />
        <div className={Styles.scrollText}>Scroll</div>
      </div>
    </div>
  );
};

export default Hero;
