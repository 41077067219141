import React, { useEffect, useState, useRef } from 'react';
import {
  css,
  getSlideIndexFromProgress,
  isTouchDevice,
  q,
  trackEvent,
} from '@utils';
import { InView } from 'react-intersection-observer';
import { ShortArrow } from '@shared/Icons/Icons';
import useWindowSize from '@hooks/useWindowSize';
import * as gsap from 'gsap';
import Grantee from './Grantee';

import Styles from './GranteesSpotlightCarousel.module.scss';
import GranteeStorySideNav from './GranteeStorySideNav';

const GranteesSpotlightCarousel = ({
  heroEyebrow,
  heroTitle,
  heroSubtitle,
  granteesStoryList,
}) => {
  const [bodyEl, setBodyEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeStory, setActiveStory] = useState(-1);
  const [mounted, setMounted] = useState(false);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);
  const carouselRef = useRef(null);
  const guestureDirection = useRef('left');
  const carouselProgress = useRef(0);
  const currentProgress = useRef(0);
  const pressPoint = useRef(null);
  const carouselTweenId = useRef(null);
  const positionTweenId = useRef(null);
  const slideIndex = useRef(0);
  const pressingDown = useRef(false);
  const { windowWidth } = useWindowSize();
  const granteeItemSize = useRef(getItemSize(windowWidth));
  const prevProgress = useRef(null);

  const moveCarousel = () => {
    carouselRef.current.style.transform = `translate3d(${currentProgress.current}px, 0, 0)`;
  };

  const tweenPosition = (from, to) => {
    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const carouselPosition = { x: from };
    positionTweenId.current = gsap.TweenLite.to(carouselPosition, 0.2, {
      x: to,
      ease: gsap.Expo.easeOut,
      onUpdate: () => {
        currentProgress.current = carouselPosition.x;
        moveCarousel();
      },
    });
  };

  const tweenToSlide = (
    index,
    animationEase = gsap.TweenLite.defaultEase,
    animationDuration = 0.35
  ) => {
    const progressTween = { val: currentProgress.current };
    slideIndex.current = index;
    updateDesktopArrows(index);

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    if (positionTweenId.current) {
      positionTweenId.current.kill();
    }

    carouselTweenId.current = gsap.TweenLite.to(
      progressTween,
      animationDuration,
      {
        val: -1 * index * (carouselWidth / granteesStoryList.length),
        ease: animationEase,
        onUpdate: () => {
          currentProgress.current = progressTween.val;
          carouselProgress.current = progressTween.val;

          moveCarousel();
        },
      }
    );
  };

  const onPressStart = e => {
    if (e.touches) {
      pressPoint.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    } else {
      pressPoint.current = { x: e.clientX, y: e.clientY };
    }
    pressingDown.current = true;
    prevProgress.current = currentProgress.current;
  };

  const onPressMove = e => {
    if (!pressPoint.current) {
      return;
    }

    if (!pressingDown.current) {
      return;
    }

    if (carouselTweenId.current) {
      carouselTweenId.current.kill();
    }

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    guestureDirection.current =
      clientX < pressPoint.current.x
        ? 'left'
        : clientX > pressPoint.current.x
        ? 'right'
        : null;
    const timelineDelta = clientX - pressPoint.current.x;

    tweenPosition(
      currentProgress.current,
      (carouselProgress.current || 0) + timelineDelta
    );
  };

  const onPressEnd = () => {
    pressingDown.current = false;

    const progressX =
      (-1 * currentProgress.current) /
      (carouselWidth / granteesStoryList.length);

    const progressIndex =
      guestureDirection.current === 'left'
        ? Math.floor(progressX)
        : guestureDirection.current === 'right'
        ? Math.ceil(progressX)
        : Math.round(progressX);

    const progressModulus = progressX % 1;

    const newIndex = getSlideIndexFromProgress(
      guestureDirection.current,
      progressModulus,
      progressIndex
    );

    const constrainedSlideIndex = getConstrainedIndex(
      newIndex,
      granteesStoryList.length,
      100 / granteeItemSize.current // items per width =>  2.5 etc
    );

    tweenToSlide(constrainedSlideIndex, gsap.TweenLite.defaultEase);
    carouselProgress.current = currentProgress.current;
  };

  const setBodyFixed = setFixed => {
    const body = document.body;
    const scrollY = document.documentElement.style.getPropertyValue(
      '--scroll-y'
    );

    if (setFixed && !open) {
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    } else if (open) {
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY) * -1);
    }
  };

 
  const onStoryClick = index => {
    setBodyFixed(true);
    setActiveStory(index);
    setOpen(true);

    const grantee = granteesStoryList.length > 0 && granteesStoryList[index];
    const { storyTitle } = grantee;

    trackEvent({
      category: 'Impact',
      action: null,
      label: `Our Impact In Action - ${storyTitle || `Story ${index}`}`,
    });
  };

  const onFooterCtaClick = newIndex => {
    const constrainedSlideIndex = Math.max(
      0,
      Math.min(newIndex, granteesStoryList.length - 1)
    );

    const nextStory = granteesStoryList[constrainedSlideIndex];
    const { storyTitle: nextStoryTitle } = nextStory;
    const { storyTitle: currentStoryTitle } = granteesStoryList[activeStory];

    trackEvent({
      category: 'Impact',
      action: nextStoryTitle || `Story ${constrainedSlideIndex + 1}`,
      label: `${currentStoryTitle || `Story ${activeStory + 1}`}__${
        activeStory < constrainedSlideIndex ? 'Next' : 'Back'
      }`,
    });

    setActiveStory(constrainedSlideIndex);
    // scroll container back to top
  };

  const onArrowClick = direction => {
    let newIndex = 0;
    if (direction === 'left') {
      newIndex = slideIndex.current - 1;
    } else {
      newIndex = slideIndex.current + 1;
    }

    const constrainedSlideIndex = getConstrainedIndex(
      newIndex,
      granteesStoryList.length,
      100 / granteeItemSize.current // items per width =>  2.5 etc
    );

    tweenToSlide(constrainedSlideIndex, gsap.Power2.easeInOut, 1.2);
  };

  const updateDesktopArrows = (index = 0) => {
    const itemsPerWidth = 100 / granteeItemSize.current;
    const maxSlideIndex =
      granteesStoryList.length > Math.floor(itemsPerWidth)
        ? granteesStoryList.length - Math.floor(itemsPerWidth)
        : granteesStoryList.length - 1;

    if (index === 0) {
      leftArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowLeft,
        Styles.disabled
      );
    } else {
      leftArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowLeft
      );
    }

    if (index === maxSlideIndex) {
      rightArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowRight,
        Styles.disabled
      );
    } else {
      rightArrowRef.current.className = css(
        Styles.carouselArrow,
        Styles.carouselArrowRight
      );
    }
  };

  const onResize = () => {
    granteeItemSize.current = getItemSize(window.innerWidth);
    tweenToSlide(slideIndex.current);
  };

  const bindListeners = () => {
    if (!isTouchDevice) {
      window.addEventListener('resize', onResize);
    }
  };

  useEffect(() => {
    bindListeners();
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`
      );
    });
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      updateDesktopArrows();
    }
  }, [mounted]);

  useEffect(() => {
    if (carouselRef.current) {
      setCarouselWidth(carouselRef.current.getBoundingClientRect().width);
    }
  });

  useEffect(() => {
    setMounted(true);
    setBodyEl(q('body'));
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <InView triggerOnce threshold={0}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(
            Styles.granteesSpotlightCarousel,
            inView && Styles.inView
          )}
        >
          <GranteeStorySideNav
            bodyEl={bodyEl}
            storiesList={granteesStoryList}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            setBodyFixed={setBodyFixed}
            activeStory={activeStory}
            onFooterCtaClick={onFooterCtaClick}
            windowWidth={windowWidth}
          />

          <div className={Styles.copyHero}>
            <div className={Styles.copyHeroBackground} />
            <div className={Styles.copyHeroContainer}>
              <div className={Styles.heroEyebrow}>{heroEyebrow}</div>
              <div className={Styles.heroTitle}>{heroTitle}</div>
              <div className={Styles.heroSubtitle}>{heroSubtitle}</div>
            </div>
          </div>
          <div className={Styles.membersListContainer}>
            <div
              className={css(
                Styles.carouselArrow,
                Styles.carouselArrowLeft,
                Styles.disabled
              )}
              ref={leftArrowRef}
              onClick={() => {
                onArrowClick('left');
              }}
            >
              <ShortArrow iconStyles={Styles.arrowIcon} />
            </div>
            <div
              className={css(Styles.carouselArrow, Styles.carouselArrowRight)}
              ref={rightArrowRef}
              onClick={() => {
                onArrowClick('right');
              }}
            >
              <ShortArrow iconStyles={Styles.arrowIcon} />
            </div>
            <ul
              role="presentation"
              className={Styles.membersList}
              style={{
                width: `${granteesStoryList.length * granteeItemSize.current}%`,
              }}
              ref={carouselRef}
              onTouchStart={onPressStart}
              onTouchMove={onPressMove}
              onTouchEnd={onPressEnd}
              onMouseDown={onPressStart}
              onMouseMove={onPressMove}
              onMouseUp={onPressEnd}
            >
              {granteesStoryList.map((story, index) => {
                return (
                  <Grantee
                    key={index}
                    {...story}
                    index={index}
                    onStoryClick={onStoryClick}
                    storyActive={activeStory === index}
                    granteeItemSize={granteeItemSize.current}
                    inView={inView}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </InView>
  );
};

const getConstrainedIndex = (newIndex, total, itemsPerWidth) => {
  return Math.min(
    Math.max(0, newIndex),
    total > Math.floor(itemsPerWidth)
      ? total - Math.floor(itemsPerWidth)
      : total - 1
  );
};

const getItemSize = windowWidth => {
  if (windowWidth < 1024) {
    return 80;
  }

  if (windowWidth < 1200) {
    return 60;
  }

  return 45;
};

export default GranteesSpotlightCarousel;
