import React from 'react';
import { InView } from 'react-intersection-observer';
import { css, trackEvent } from '@utils';
import Styles from './SocialModule.module.scss';

const SocialModule = ({ darkTheme, eyebrow, title, subtitle, links }) => {
  const onSocialLinkClick = (e, label, url) => {
    e.preventDefault();
    const newWindow = window.open(url, '_blank');
    newWindow.opener = null;

    trackEvent({
      category: 'Social Module',
      action: url,
      label,
    });
  };

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(
            Styles.socialModule,
            darkTheme && Styles.darkTheme,
            inView && Styles.inView
          )}
        >
          <div className={Styles.copyContainer}>
            <div className={Styles.eyebrow}>{eyebrow}</div>
            <h2 className={Styles.title}>{title}</h2>
            <h4 className={Styles.subtitle}>{subtitle}</h4>
            <ul className={Styles.socialLinks}>
              {links.map(({ label, url }, index) => {
                return (
                  <li
                    key={index}
                    className={Styles.socialLink}
                    style={{ animationDelay: `${index * 100 + 300}ms` }}
                  >
                    <a
                      href={url}
                      onClick={e => {
                        onSocialLinkClick(e, label, url);
                      }}
                    >
                      {label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </InView>
  );
};

export default SocialModule;
