import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import TextHero from './TextHero';

const TextHeroQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const subtitle = idx(props, _ => _.subtitle);
  return <TextHero eyebrow={eyebrow} title={title} subtitle={subtitle} />;
};

export default TextHeroQuery;

export const query = graphql`
  fragment TextHero on ContentfulTextHero {
    eyebrow
    title
    subtitle
  }
`;
