import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Hero from './Hero';

const HeroQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const subtitle = idx(props, _ => _.subtitle);
  const mobileHeroImage = idx(props, _ => _.mobileHeroImage);
  const desktopHeroImage = idx(props, _ => _.desktopHeroImage);

  return (
    <Hero
      eyebrow={eyebrow}
      title={title}
      subtitle={subtitle}
      mobileHeroImage={mobileHeroImage}
      desktopHeroImage={desktopHeroImage}
    />
  );
};

export default HeroQuery;

export const query = graphql`
  fragment Hero on ContentfulHeroModule {
    eyebrow
    title
    subtitle
    mobileHeroImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopHeroImage {
      description
      fluid(maxWidth: 1920, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
