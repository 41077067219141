import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import stateAbbreviations from '@data/stateAbbreviations';
import InteractiveMap from './InteractiveMap';

const InteractiveMapQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const subtitle = idx(props, _ => _.subtitle);
  const grantsList = (idx(props, _ => _.grantsList) || []).map(grant => {
    return {
      year: idx(grant, _ => _.year),
      state: idx(grant, _ => _.state),
      location: idx(grant, _ => _.location),
      population: idx(grant, _ => _.population),
      beneficiary: idx(grant, _ => _.beneficiary),
      grantDescription: idx(
        grant,
        _ => _.grantDescription.childMarkdownRemark.html
      ),
    };
  });

  const grantYearEditorials = (
    idx(props, _ => _.grantYearEditorialContent) || []
  ).map(editiorial => {
    return {
      year: idx(editiorial, _ => _.year),
      eyebrow: idx(editiorial, _ => _.eyebrow),
      title: idx(editiorial, _ => _.title),
      subtitle: idx(editiorial, _ => _.subtitle),
      image: idx(editiorial, _ => _.image),
    };
  });

  const organizedEditorialList = organizeEditorialData(grantYearEditorials);

  const organizedGrantsList = organizeGrantData(grantsList);

  return (
    <InteractiveMap
      eyebrow={eyebrow}
      title={title}
      subtitle={subtitle}
      data={organizedGrantsList}
      editorialData={organizedEditorialList}
    />
  );
};

export default InteractiveMapQuery;

export const query = graphql`
  fragment GrantMapModule on ContentfulGrantMapModule {
    eyebrow
    title
    subtitle
    grantsList {
      year
      state
      location
      population
      beneficiary
      grantDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    grantYearEditorialContent {
      year
      eyebrow
      title
      subtitle
      image {
        description
        fluid(maxWidth: 602, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const organizeEditorialData = originalData => {
  const editorialDataOrganized = {};

  originalData.forEach(editorial => {
    const { year } = editorial;
    if (!editorialDataOrganized[year]) {
      editorialDataOrganized[year] = {};
    }

    editorialDataOrganized[year] = {
      ...editorial,
    };
  });

  return editorialDataOrganized;
};

const organizeGrantData = originalData => {
  const yearsObj = {};
  // create year dictionary
  originalData.forEach(grant => {
    const {
      year,
      state,
      location,
      population,
      beneficiary,
      grantDescription,
    } = grant;

    // Make sure '[year]' key exists
    if (!yearsObj[year]) {
      yearsObj[year] = {};

      // Make sure 'states' key on year exists
      yearsObj[year].states = {};
    }

    // If state key doesn't exist yet, create it.
    if (!yearsObj[year].states[state]) {
      yearsObj[year].states[state] = {};
      yearsObj[year].states[state].grants = [];
    }

    // push grant to `[year].[state].grants`
    yearsObj[year].states[state].grants.push({
      location,
      population,
      beneficiary,
      grant: grantDescription,
    });
  });

  // Convert keys to array of objects with no keys to match original data format.
  const organizedYearData = Object.keys(yearsObj).map(yearKey => {
    const yearData = yearsObj[yearKey];
    const formattedStates = Object.keys(yearData.states).map(stateKey => {
      const stateData = yearsObj[yearKey].states[stateKey];

      return {
        name: stateKey,
        abbreviation: stateAbbreviations[stateKey],
        grants: stateData.grants,
      };
    });
    return {
      year: yearKey,
      states: formattedStates,
    };
  });

  return organizedYearData;
};
