import React from 'react';
import { navigate } from 'gatsby';
import { InView } from 'react-intersection-observer';
import { css, trackEvent } from '@utils';
import Img from 'gatsby-image/withIEPolyfill';
import {ShortArrow} from '@shared/Icons/Icons';

import Styles from './GrantTestimonial.module.scss';

const GrantTestimonial = ({
  quoteCopy,
  quoteAuthorName,
  quoteAuthorTitle,
  applyForGrantCta,
  applyForGrantCtaUrl,
  desktopImage,
  mobileImage,
}) => {
  const onApplyClick = e => {
    e.preventDefault();

    // Go to internal donation page
    if (!applyForGrantCtaUrl || applyForGrantCtaUrl === '/grant') {
      trackEvent({
        category: 'Impact',
        action: `${window.location.origin}/grant`,
        label: 'Apply For Grant',
      });

      navigate('/grant');
    } else {
      // Go to external donation page. We should be on /grant page already.
      const newWindow = window.open(applyForGrantCtaUrl, '_blank');
      newWindow.opener = null;
      trackEvent({
        category: 'Apply For A Grant',
        action: applyForGrantCtaUrl,
        label: 'Apply For Grant',
      });
    }
  };

  return (
    <InView triggerOnce threshold={0.2}>
      {({ inView, ref }) => (
        <div
          ref={ref}
          className={css(Styles.grantTestimonial, inView && Styles.inView)}
        >
          {desktopImage && desktopImage.fluid && (
            <Img
              className={Styles.desktopBackgroundImage}
              fluid={desktopImage.fluid}
            />
          )}

          {mobileImage && mobileImage.fluid && (
            <Img
              className={Styles.mobileBackgroundImage}
              fluid={mobileImage.fluid}
            />
          )}

          <div className={Styles.copyContainer}>
            <div className={Styles.copyContainerBackground} />
            <div className={Styles.applyCtaBtn}>
              {!applyForGrantCtaUrl || applyForGrantCtaUrl === '/grant' ? (
                <div>
                  <a
                    className={Styles.grantsButtonDesktop}
                    href={applyForGrantCtaUrl}
                    onClick={onApplyClick}
                  >
                    <div className={Styles.grantsButtonDesktop_copy}>
                      {applyForGrantCta}
                    </div>
                    <ShortArrow iconStyles={Styles.grantsButtonDesktop_arrow}/>
                  </a>
                  <a
                    className={Styles.applyCtaCopy}
                    href={applyForGrantCtaUrl}
                    onClick={onApplyClick}
                  >
                    {applyForGrantCta}
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    className={Styles.grantsButtonDesktop}
                    href={applyForGrantCtaUrl}
                    onClick={onApplyClick}
                  >
                    <div className={Styles.grantsButtonDesktop_copy}>
                      {applyForGrantCta}
                    </div>
                    <ShortArrow iconStyles={Styles.grantsButtonDesktop_arrow}/>
                  </a>
                  <a
                    className={Styles.applyCtaCopy}
                    href={applyForGrantCtaUrl}
                    onClick={onApplyClick}
                  >
                    {applyForGrantCta}
                  </a>
                </div>
              )}
              <div className={Styles.applyCtaArrow} />
            </div>
            <div className={Styles.quote}>
              <div className={Styles.quoteCopyContainer}>
                <div className={Styles.quoteCopy}>
                  <span className={Styles.startQuote}>“</span>
                  <span className={Styles.quoteCopyText}>{quoteCopy}</span>
                  <span className={Styles.endQuote}>”</span>
                </div>
              </div>
              <div className={Styles.quoteAuthorContainer}>
                <div className={Styles.quoteAuthorName}>{quoteAuthorName}</div>
                <div className={Styles.quoteAuthorTitle}>
                  {quoteAuthorTitle}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </InView>
  );
};

export default GrantTestimonial;
