import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import GranteesSpotlightCarousel from './GranteesSpotlightCarousel';

const GranteesSpotlightCarouselQuery = props => {
  const heroEyebrow = idx(props, _ => _.heroEyebrow);
  const heroTitle = idx(props, _ => _.heroTitle);
  const heroSubtitle = idx(props, _ => _.heroSubtitle);
  const granteesStoryList = (idx(props, _ => _.grantees) || []).map(story => {
    return {
      thumbnailEyebrow: idx(story, _ => _.thumbnailEyebrow),
      thumbnailTitle: idx(story, _ => _.thumbnailTitle),
      thumbnailImage: idx(story, _ => _.thumbnailImage),
      storyEyebrow: idx(story, _ => _.storyEyebrow),
      storyTitle: idx(story, _ => _.storyTitle),
      storySubtitle: idx(story, _ => _.storySubtitle),
      storyParagraph1: idx(story, _ => _.storyParagraph1),
      storyParagraph2: idx(story, _ => _.storyParagraph2),
      storyParagraph3: idx(story, _ => _.storyParagraph3),
      storyQuoteImage: idx(story, _ => _.storyQuoteImage),
      storyQuoteCopy: idx(story, _ => _.storyQuoteCopy),
      storyQuoteAuthorName: idx(story, _ => _.storyQuoteAuthorName),
      storyQuoteAuthorTitle: idx(story, _ => _.storyQuoteAuthorTitle),
      storyImageCarousel: idx(story, _ => _.storyImageCarousel),
    };
  });





  return (
    <GranteesSpotlightCarousel
      heroEyebrow={heroEyebrow}
      heroTitle={heroTitle}
      heroSubtitle={heroSubtitle}
      granteesStoryList={granteesStoryList}

    />
  );
};

export default GranteesSpotlightCarouselQuery;


export const query = graphql`
  fragment GranteesSpotlightModule on ContentfulGranteesSpotlightModule {
    heroEyebrow
    heroTitle
    heroSubtitle
    grantees {
      thumbnailEyebrow
      thumbnailTitle
      thumbnailImage {
        description
        fluid(maxWidth: 612, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      storyEyebrow
      storyTitle
      storySubtitle
      storyParagraph1 {
        childMarkdownRemark {
          html
        }
      }
      storyParagraph2 {
        childMarkdownRemark {
          html
        }
      }
      storyParagraph3 {
        childMarkdownRemark {
          html
        }
      }
      storyQuoteImage {
        description
        fluid(maxWidth: 612, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      storyQuoteCopy
      storyQuoteAuthorName
      storyQuoteAuthorTitle
      storyImageCarousel {
        description
        fluid(maxWidth: 612, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }

  }
`;
