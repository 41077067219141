import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import GrantTestimonial from './GrantTestimonial';

const GrantTestimonialQuery = props => {
  const quoteCopy = idx(props, _ => _.quoteCopy);
  const quoteAuthorName = idx(props, _ => _.quoteAuthorName);
  const quoteAuthorTitle = idx(props, _ => _.quoteAuthorTitle);
  const applyForGrantCta = idx(props, _ => _.applyForGrantCta);
  const applyForGrantCtaUrl = idx(props, _ => _.applyForGrantCtaUrl);
  const mobileImage = idx(props, _ => _.mobileImage);
  const desktopImage = idx(props, _ => _.desktopImage);

  return (
    <GrantTestimonial
      quoteCopy={quoteCopy}
      quoteAuthorName={quoteAuthorName}
      quoteAuthorTitle={quoteAuthorTitle}
      applyForGrantCta={applyForGrantCta}
      applyForGrantCtaUrl={applyForGrantCtaUrl}
      desktopImage={desktopImage}
      mobileImage={mobileImage}
    />
  );
};

export default GrantTestimonialQuery;

export const query = graphql`
  fragment GrantTestimonial on ContentfulGrantTestimonial {
    quoteCopy
    quoteAuthorName
    quoteAuthorTitle
    applyForGrantCta
    applyForGrantCtaUrl
    mobileImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    desktopImage {
      description
      fluid(maxWidth: 1200, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
