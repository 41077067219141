import React from 'react';
import { css } from '@utils';
import Img from 'gatsby-image/withIEPolyfill';
import StoryImageCarousel from './StoryImageCarousel';
import Styles from './FeaturedStoryContent.module.scss';

const FeaturedStoryContent = ({
  storyEyebrow,
  storyTitle,
  storySubtitle,
  storyParagraph1,
  storyParagraph2,
  storyParagraph3,
  storyQuoteCopy,
  storyQuoteImage,
  storyQuoteAuthorName,
  storyQuoteAuthorTitle,
  storyImageCarousel,
  windowWidth,
}) => {
  return (
    <div className={Styles.featuredStoryContent}>
      <div className={Styles.paddingWrapper}>
        <div className={Styles.storyEyebrow}>{storyEyebrow}</div>
        <div className={Styles.storyTitle}>{storyTitle}</div>
        <div className={Styles.storySubtitle}>{storySubtitle}</div>

        {storyParagraph1 && (
          <div
            className={css(Styles.paragraph, Styles.paragraph1)}
            dangerouslySetInnerHTML={{
              __html: storyParagraph1.childMarkdownRemark.html,
            }}
          />
        )}
      </div>

      {storyQuoteImage && storyQuoteImage.fluid && storyQuoteCopy && (
        <div className={Styles.storyQuote}>
          <div className={Styles.quoteImage}>
            {storyQuoteImage && storyQuoteImage.fluid && (
              <Img fluid={storyQuoteImage.fluid} />
            )}
          </div>
          <div className={Styles.quoteCopyContainer}>
            <div className={Styles.quote}>
              <span className={Styles.startQuote}>“</span>
              <span className={Styles.quoteCopy}>{storyQuoteCopy}</span>
              <span className={Styles.endQuote}>”</span>
            </div>
            <div className={Styles.authorCopy}>
              <div className={Styles.authorName}>{storyQuoteAuthorName}</div>
              <div className={Styles.authorTitle}>{storyQuoteAuthorTitle}</div>
            </div>
          </div>
        </div>
      )}

      <div className={Styles.paddingWrapper}>
        {storyParagraph2 && (
          <div
            className={css(Styles.paragraph, Styles.paragraph2)}
            dangerouslySetInnerHTML={{
              __html: storyParagraph2.childMarkdownRemark.html,
            }}
          />
        )}
      </div>

      {storyImageCarousel && storyImageCarousel.length > 0 && (
        <StoryImageCarousel
          carouselSlides={storyImageCarousel}
          windowWidth={windowWidth}
        />
      )}

      <div className={Styles.paddingWrapper}>
        {storyParagraph3 && (
          <div
            className={css(Styles.paragraph, Styles.paragraph3)}
            dangerouslySetInnerHTML={{
              __html: storyParagraph3.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
    </div>
  );
};
export default FeaturedStoryContent;
