import React from 'react';
import { graphql } from 'gatsby';
import Meta from '@shared/Meta/Meta';
import DefaultLayout from '@layouts';
import InteractiveMap from '@components/InteractiveMap/InteractiveMap.query';
import Hero from '@components/Hero/Hero.query';
import TextHero from '@components/TextHero/TextHero.query';
import GrantTestimonial from '@components/GrantTestimonial/GrantTestimonial.query';
import DonationModule from '@shared/DonationModule/DonationModule.query';
import SocialModule from '@shared/SocialModule/SocialModule.query';
import GranteesSpotlightCarousel from '@components/GranteesSpotlightCarousel/GranteesSpotlightCarousel.query';

const Impact = ({ data }) => {
  const hero = data.allContentfulOurImpactPage.edges.map(
    edge => edge.node.hero
  )[0];

  const textHero = data.allContentfulOurImpactPage.edges.map(
    edge => edge.node.textHero
  )[0];

  const grantMapModule = data.allContentfulOurImpactPage.edges.map(
    edge => edge.node.grantMapModule
  )[0];

  const grantTestimonial = data.allContentfulOurImpactPage.edges.map(
    edge => edge.node.grantTestimonial
  )[0];

  const globalSiteData = data.allContentfulGlobalSiteData.edges.map(
    edge => edge.node
  )[0];

  const granteesSpotlightModule = data.allContentfulOurImpactPage.edges.map(
    edge => edge.node.granteesSpotlightModule
  )[0];




  const {
    socialModuleEyebrow,
    socialModuleTitle,
    socialModuleSubtitle,
    donationModuleEyebrow,
    donationModuleTitle,
    donationModuleSubtitle,
    facebookLink,
    twitterLink,
    instagramLink,
    youTubeLink,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    cookieBannerCopy,
    cookieBannerCtaCopy,
    phoneNumber,
    contactEmails,
  } = globalSiteData;

  const footerData = {
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,
    phoneNumber,
    facebookLink,
    twitterLink,
    instagramLink,
    contactEmails,
    youTubeLink
  };

  const cookieBanner = {
    cookieBannerCopy,
    cookieBannerCtaCopy,
  };

  return (
    <DefaultLayout
      footerData={footerData}
      cookieBanner={cookieBanner}
      pageName="Impact"
    >
      <Meta {...globalSiteData} />
      <Hero {...hero} />
      <TextHero {...textHero} />
      <InteractiveMap {...grantMapModule} />
      <GranteesSpotlightCarousel {...granteesSpotlightModule} />
      <GrantTestimonial {...grantTestimonial} />
      <SocialModule
        eyebrow={socialModuleEyebrow}
        title={socialModuleTitle}
        subtitle={socialModuleSubtitle}
        links={[
          {
            label: 'Instagram',
            url: instagramLink,
          },
          {
            label: 'Facebook',
            url: facebookLink,
          },
          {
            label: 'Twitter',
            url: twitterLink,
          },
          {
            label: 'YouTube',
            url: youTubeLink
          }
        ]}
      />
      <DonationModule
        donationModuleEyebrow={donationModuleEyebrow}
        donationModuleTitle={donationModuleTitle}
        donationModuleSubtitle={donationModuleSubtitle}
      />
    </DefaultLayout>
  );
};

export default Impact;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulGlobalSiteData {
      edges {
        node {
          ...Meta
          ...DonationModule
          ...SocialModule
        }
      }
    }

    allContentfulOurImpactPage {
      edges {
        node {
          hero {
            ...Hero
          }
          textHero {
            ...TextHero
          }

          grantMapModule {
            ...GrantMapModule
          }

          grantTestimonial {
            ...GrantTestimonial
          }

          granteesSpotlightModule {
            ...GranteesSpotlightModule
          }
        }
      }
    }
  }
`;
