import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';
import SocialModule from './SocialModule';

const SocialModuleQuery = props => {
  const darkTheme = idx(props, _ => _.darkTheme);
  const eyebrow = idx(props, _ => _.eyebrow);
  const title = idx(props, _ => _.title);
  const subtitle = idx(props, _ => _.subtitle);
  const links = (idx(props, _ => _.links) || []).map(link => {
    return {
      label: idx(link, _ => _.label),
      url: idx(link, _ => _.url),
    };
  });

  return (
    <SocialModule
      darkTheme={darkTheme}
      eyebrow={eyebrow}
      title={title}
      subtitle={subtitle}
      links={links}
    />
  );
};

export const query = graphql`
  fragment SocialModule on ContentfulGlobalSiteData {
    socialModuleEyebrow
    socialModuleTitle
    socialModuleSubtitle
  }
`;

export default SocialModuleQuery;
