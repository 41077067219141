/* eslint-disable */

import React from "react";
import {useState, useEffect} from "react";
import Styles from "./SliderMap.module.scss";
import {TweenMax} from "gsap";

import { InView } from 'react-intersection-observer';

export default class SliderMap extends React.Component {

    constructor() {
        super();
        this.state = {
            selected: 0,
            year: ""
        }
        this.index = 0;
        this.percent = 0;
        this.prevIndex = 0;
        this.scroll = 0;
        this.initMapResize = true;
    }

    componentDidUpdate() {
        if(this.props.forceYear.force && this.guideData) {

            this.prevIndex = this.props.forceYear.year;

            this.percent = Math.floor( 100 * this.prevIndex /  (this.props.data.length - 1));

            this.slider.style.transform = `translate(${this.percent * this.guideData.width / 100 - 15}px, -15px)`;

            const year = (this.prevIndex === 0 || this.prevIndex === this.yearsData.length - 1) ? "" : this.yearsData[this.prevIndex];

            if(this.state.year !== year) this.setState({year: year});

            if(this.prevIndex !== this.state.selected) this.setState({selected: this.prevIndex});

        }
    }

    componentDidMount() {

        this.slider = document.querySelector(`.${Styles.slider}`);

        this.yearsData = this.props.data.map(el => el.year);

        this.resize();

        this.slider.addEventListener("mousedown", this.startSliding.bind(this));

        window.addEventListener("mouseup", this.stopSliding.bind(this));

        this.slider.addEventListener("touchstart", this.startSliding.bind(this));

        window.addEventListener("touchend", this.stopSliding.bind(this));

        window.addEventListener("mousemove", this.updateSlider.bind(this));

        window.addEventListener("touchmove", this.updateSlider.bind(this));

        window.addEventListener("resize", this.resize.bind(this));

        this.sliding = false;

    }


    componentWillUnmount() {

        this.slider.removeEventListener("mousedown", this.startSliding.bind(this));

        window.removeEventListener("mouseup", this.stopSliding.bind(this));

        this.slider.removeEventListener("touchstart", this.startSliding.bind(this));

        window.removeEventListener("touchend", this.stopSliding.bind(this));

        window.removeEventListener("mousemove", this.updateSlider.bind(this));

        window.removeEventListener("touchmove", this.updateSlider.bind(this));

        window.removeEventListener("resize", this.resize.bind(this));

        this.sliding = false;
    }

    resize() {
        const sliderWrapperEl = document.querySelector(`.${Styles.sliderWrapper}`);
        this.guideData = sliderWrapperEl ? sliderWrapperEl.getBoundingClientRect() : null;
        const wrapperEl = document.querySelector(`.${Styles.wrapper}`);
        this.wrapperData = wrapperEl ? wrapperEl.getBoundingClientRect() : null;        
        this.updateSliderOnClick(this.index, false, this.initMapResize);
        this.initMapResize = false;
    }

    startSliding() {
        this.sliding = true;
        TweenMax.killTweensOf(this);
    }

    stopSliding() {

        if(this.sliding) {

            this.sliding = false;

            const segmentLength = 100 / (this.props.data.length - 1);

            const anchor = Math.round(this.percent / segmentLength);

            this.updateSliderOnClick(anchor);

        }

    }

    updateSlider(e) {

        if(this.sliding && this.guideData) {

            e.preventDefault();

            /*
            This part is done for the sticky section, the slider should stick around the year.
             */
            const _x = e.clientX || e.touches[0].pageX;

            const displace = Math.min(Math.max(_x - this.guideData.left, 0), this.guideData.width);

            this.percent = Math.floor( 100 * (displace) /  this.guideData.width);

            this.slider.style.transform = `translate(${displace - 15}px, -15px)`;

            const segmentLength = 100 / (this.props.data.length - 1);

            const anchor = Math.round(this.percent / segmentLength);

            const year = (anchor === 0 || anchor === this.yearsData.length - 1) ? "" : this.yearsData[anchor];

            if(this.state.year !== year) this.setState({year: year});

            this.updateStates(this.percent);

        }

    }


    updateStates(_percent, clear = true) {

        this.props.updateStates(_percent, this.yearsData.length, clear);
    }


    updateSliderOnClick(index, useBlur = false, clearYear = true) {        
        this.index = index;

        if(index === 0 || index === this.yearsData.length - 1)  this.setState({year: ""});

        const base = 0.3;
        const animationTime = Math.max(base * Math.abs(this.index - this.prevIndex), base);

        this.prevIndex = index;

        TweenMax.to(this, animationTime, {percent:  100 * index / (this.props.data.length - 1), onUpdate: () => {
            if(this.guideData) {
                this.slider.style.transform = `translate(${this.percent * this.guideData.width / 100 - 15}px, -15px)`;
            }
            this.updateStates(this.percent, clearYear);

        }, onComplete: () => {
            this.updateSelection(index);
        }});
    }

    launchSliderAnimation() {

        this.setState({year: ""});
        this.updateSliderOnClick( this.yearsData.length - 1, false, true);

    }

    updateSelection(index) {
        this.props.updateYear(index);
        this.setState({selected: index});
    }

    render() {
        let years = [];
        let dots = [];

        const yearsData = this.props.data.map(el => el.year);

        yearsData.map((el, index) => {
            years.push(
                <div key = {`years${index}`} onClick = {() => {this.updateSliderOnClick(index, true)}} className = {`${Styles.year} ${this.state.selected === index ? Styles.yearSelected : ""}`} style = {{left: `${Math.floor(100 * index / (this.props.data.length - 1))}%`}}>{el}</div>
            )

            dots.push(
                <div key = {`dots${index}`} className = {Styles.dot} style = {{left: `${Math.floor(100 * index / (this.props.data.length - 1))}%`}} />
            )
        });

        return (
            <InView triggerOnce threshold={1} onChange = {inView => {if(inView) this.launchSliderAnimation()}} >
                {({ inView, ref }) => (
                    <div ref={ref} className = {Styles.wrapper} >

                        <div className = {Styles.yearsWrapper}>
                            {years}
                        </div>

                        <div className = {Styles.sliderWrapper}>
                            {dots}
                            <div className = {Styles.slider}>

                                <div className = {Styles.sliderYear}>{this.state.year}</div>

                                <svg className = {Styles.arrowLeft} xmlns="http://www.w3.org/2000/svg" width="6.121" height="9.414" viewBox="0 0 6.121 9.414">
                                    <path  d="M0,0,4,4,8,0" transform="translate(5.414 0.707) rotate(90)" fill="none" stroke="#0b1e28" strokeWidth="2"/>
                                </svg>

                                <svg className = {Styles.arrowRight} xmlns="http://www.w3.org/2000/svg" width="6.121" height="9.414" viewBox="0 0 6.121 9.414">
                                    <path  d="M0,4,4,0,8,4" transform="translate(4.707 0.707) rotate(90)" fill="none" stroke="#0b1e28" strokeWidth="2"/>
                                </svg>

                            </div>
                        </div>

                    </div>
                )}
            </InView>
        )
    }

}